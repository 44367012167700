.pledge {
    display: flex;
    flex-direction: column;
    position: fixed;
    /* position: relative; */
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.pledge__children {
    position: relative;
    flex: 1;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.pledge__content {
    flex: 1;
    width: 100%;
    height: auto;
    padding: 0 16px;
    background-image: url(../../assets/images/pledge_bg.png);
    background-size: 100% 100%;
    padding-bottom: 0.26667rem;
    overflow-x: hidden;
    overflow-y: scroll;
}

.pledge__img {
    margin: 16px 0;
    width: calc(100vw - 32px);
    height: calc((100vw - 32px) / 0.9);
    border-radius: 10px;
}

.pledge__img img {
    width: 100%;
    height: 100%;
    border-radius: 12.5px;
}

.pledge__card {
    padding: 10px 15px;
    background-color: #21343e;
    border: 1px solid #30d5c1;
    border-radius: 7.5px;
}

.pledge__card__item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.pledge__card__item span:first-child {
    color: #ced6ce;
    font-size: 14.5px;
}

.pledge__card__item span:last-child {
    color: white;
    font-size: 16.5px;
}

.pledge__input {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background: #010405;
    border-radius: 22px;
    border: 0.02667rem solid #b9cfc8;
    padding: 6px 15px;
    margin: 16px 0 0;
}

.pledge__input__input {
    flex: 1;
    color: white;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    font-size: 13px;
}

.pledge__input__input::placeholder {
    color: white;
}

.pledge__input__coy {
    padding: 0 8px;
    color: #30d5c1;
    font-size: 14px;
    /* border-left: 1px solid #30d5c1; */
}

.pledge__input__icon {
    margin: 0 8px;
    width: 16px;
    height: 16px;
}

.pledge__input__icon img {
    width: 100%;
    height: 100%;
}

.pledge__selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 43px;
    right: 20px;
    width: 133px;
    height: 170px;
    background-color: white;
    border-radius: 7.5px;
}

.pledge__selector__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
    color: #000;
    font-size: 14px;
}

.pledge__selector__arrowdown {
    position: absolute;
    top: 165px;
    right: 16px;
    width: 16px;
}

.pledge__submit {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #30d5c1;
    border: none;
    width: 100%;
    height: 44px;
    margin-top: 16px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 22px;
    opacity: 0.5;
}

.pledge__submit--active {
    opacity: 1;
}

.pledge__history {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 30px;
    text-decoration: underline;
    font-size: 14px;
}

.pledge__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}