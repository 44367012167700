.bag {
    position: relative;

    /* flex: 1; */
    width: 100%;
    /* height: auto; */
    height: 100%;
    background-image: url(../../assets/images/pledge_bg.png);
    background-size: 100% 100%;
    padding-bottom: 0.26667rem;
    overflow: hidden;
    /* overflow-x: hidden;
    overflow-y: scroll; */


}

.bag__header {
    position: relative;
    height: 187px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    background-color: #000;
}

.bag__useravatar {
    display: block;
    width: 66px;
    height: 66px;
    margin-right: 10px;
    position: relative;
}

.bag__userinfo {
    position: relative;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.bag__username {
    font-size: 21px;
    font-weight: 700;
    color: #ffffff;
}

.bag__usernft {
    font-size: 14px;
    color: #b4b9b5;
}

.bag__usernft span {
    color: #30d5c1;
}

.bag__header__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.bag__list {
    padding-top: 16px;
    padding-left: 16px;
}

.bag__list__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
}

.bag__list__empty img {
    width: 166px;
    height: 166px;
}

.bagloading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.7)
}