.nft__back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin: 16px;
    background: #30d5c1;
    border-radius: 50%;
}

.nft__back__icon {
    width: 15px;
    height: 15px;
}

.nftempty {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 50px 0; */
    padding: 50px 0;
}

.nftempty__icon {
    width: 166px;
    height: 166px;
}

.history {
    position: fixed;
    /* position: relative; */
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh + 16px);
    margin-top: -16px;
    padding-top: 16px;
    background-color: #000;
}