.orderpage {
    flex: 1;
    width: 100%;
    height: 100%;
    /* padding: 0 16px; */
    background-image: url(../../assets/images/pledge_bg.png);
    background-size: 100% 100%;
    padding-bottom: 0.26667rem;
    overflow-x: hidden;
    overflow-y: scroll;
}

.nftempty {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 50px 0; */
    padding: 50px 0;
}

.nftempty__icon {
    width: 166px;
    height: 166px;
}

.orderpage__list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    padding-top: 16px;
}

.orderpage__tab {
    display: flex;
    align-items: center;
}

.orderpage__tab__item {
    padding: 12px 0;
    margin: 4px 16px;
    /* border-bottom: 1px solid #30d5c1; */
    color: #ffffff;
}

.orderpage__tab__item--active {
    font-weight: bold;
    border-bottom: 1px solid #30d5c1;
}