.market__bg {
    position: absolute;
    width: 100vw;
    height: 187px;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.market__header {
    padding: 147px 16px 16px;
}

.market__tips {
    padding: 0 16px 16px;
    font-size: 15px;
    line-height: 27px;
    color: #ffffff;
}

.market__list {
    padding-left: 16px;
}