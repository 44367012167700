.history__list {
    overflow-x: scroll;
    height: calc(100vh - 60px);
    /* color: white !important; */
    /* padding: 0 20px; */
    /* background-color: white; */
    /* background-color: #17191a; */
}

.historyuser {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    background-color: #21343e;
    border: 1px solid #30d5c1;
    border-radius: 7.5px;
    margin: 0 16px 16px;
}

.historyuser__item {
    display: flex;
    justify-content: space-between;
}

.historyuser__title {
    font-size: 14px;
}

.historyuser__value {
    font-size: 16px;
}

.ant-table {
    color: white;
}

.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: white;
}

.ant-pagination-item-link {
    color: white !important;
}

.ant-pagination-item-active {
    background-color: black !important;
}

.ant-pagination-item a {
    color: white
}

.history__list .ant-table-thead>tr>th {
    color: white;
    background-color: #17191a;
}

.history__list .ant-table-column-title {
    white-space: nowrap !important;
}

.ant-table-placeholder {
    /* background-color: #17191a; */
    background-color: black;
}

.ant-empty-description {
    color: white;
}

.admin {
    color: white;
    /* background-color: #17191a; */
}

.admin__header {
    padding: 48px 8px 16px;
    font-weight: bold;
}

.admin__title {
    font-size: 16px;
}

.admin__subtitle {
    font-size: 14px;
}